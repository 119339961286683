.footer-container {
  color: #0facf2;
  background-color: #0a2c73;
  flex-direction: column;
  align-items: center;
  padding: 80px 348px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
}

.footer-container .top-wrapper {
  justify-content: space-between;
  width: 100%;
  max-width: 1224px;
  display: flex;
}

.footer-container .top-wrapper a {
  color: #e9eefb;
  text-decoration: none;
}

.footer-container .product-items, .footer-container .middle-items, .footer-container .company-items {
  flex-direction: column;
  display: flex;
}

.footer-container .product-items > *, .footer-container .middle-items > *, .footer-container .company-items > * {
  margin-bottom: 8px;
}

.footer-container .middle-items {
  margin-top: 24px;
}

.footer-container .title {
  color: #fff;
  font-weight: 700;
}

.footer-container .divider {
  background-color: #7182b6;
  width: 100%;
  max-width: 1224px;
  height: 1px;
  margin: 48px 0;
}

.footer-container .company-privacy {
  display: none;
}

.footer-container .bottom-wrapper {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1224px;
  display: flex;
}

.footer-container .bottom-wrapper a {
  color: #7182b6;
  text-decoration: none;
}

@media only screen and (width <= 1920px) {
  .footer-container {
    padding: 80px 128px;
  }
}

@media only screen and (width <= 1280px) {
  .footer-container {
    padding: 80px 60px;
  }
}

@media only screen and (width <= 820px) {
  .footer-container {
    padding: 80px 16px;
  }
}

@media only screen and (width <= 630px) {
  .footer-container {
    padding: 16px;
  }

  .footer-container .top-wrapper {
    flex-direction: column;
  }

  .footer-container .middle-items, .footer-container .company-items {
    margin-top: 32px;
  }

  .footer-container .divider {
    margin: 32px 0;
  }

  .footer-container .company-privacy {
    flex-direction: column;
    display: flex;
  }

  .footer-container .privacy-wrapper {
    display: none;
  }
}
/*# sourceMappingURL=index.f5fa3372.css.map */
