@import '/packages/site/src/public/styles/colors.scss';

$max-width: 1224px;

.footer-container {
  background-color: $ui_06;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: $neutral_03;
  padding: 80px 348px;

  .top-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-width: $max-width;

    a {
      text-decoration: none;
      color: $ui_02;
    }
  }
  
  .product-items,
  .middle-items,
  .company-items {
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: 8px;
    }
  }
  
  .middle-items {
    margin-top: 24px;
  }
  
  .title {
    font-weight: 700;
    color: $ui_01;
  }
  
  .divider {
    height: 1px;
    width: 100%;
    background-color: $text_03;
    margin: 48px 0;
    max-width: $max-width;
  }
  
  .company-privacy {
    display: none;
  }
  
  .bottom-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $max-width;
  }
  
  .bottom-wrapper a {
    text-decoration: none;
    color: $text_03;
  }
}

@media only screen and (max-width: 1920px) {
  .footer-container {
    padding: 80px 128px;
  }
}

@media only screen and (max-width: 1280px) {
  .footer-container {
    padding: 80px 60px;
  }
}

@media only screen and (max-width: 820px) {
  .footer-container {
    padding: 80px 16px;
  }
}

@media only screen and (max-width: 630px) {
  .footer-container {
    padding: 16px;

    .top-wrapper {
      flex-direction: column;
    }
  
    .middle-items,
    .company-items {
      margin-top: 32px;
    }

    .divider {
      margin: 32px 0;
    }
  
    .company-privacy {
      display: flex;
      flex-direction: column;
    }
    
  
    .privacy-wrapper {
      display: none;
    }
  }
}